import utils from "@/utils/utils";
import NavBar from "@/components/nav/index/index.vue";
import menuApi from "@/request/api/menu.js";
import loginApi from "@/request/api/user.js";

export default {
  name: "Home",
  mixins: [],
  components: {
    NavBar,
  },
  data() {
    return {
      meunlist: [
        {
          id: 1,
          name: "数据中心",
          selectNot: require(`@/assets/images/sjzxnot.png`),
          select: require(`@/assets/images/sjzxxz.png`),
          check: true,
          router: {
            name: "Overlay",
          },
        },
        {
          id: 2,
          name: "信用管理",
          selectNot: require(`@/assets/images/xyglnot.png`),
          select: require(`@/assets/images/xyglxz.png`),
          check: false,
          router: {
            name: "Labour",
          },
        },
        {
          id: 3,
          name: "监管网格",
          selectNot: require(`@/assets/images/jgwlnot.png`),
          select: require(`@/assets/images/jgwlxz.png`),
          check: false,
          router: {
            name: "Grid",
          },
        },
        {
          id: 4,
          name: "基地巡查",
          selectNot: require(`@/assets/images/jdxcnot.png`),
          select: require(`@/assets/images/jdxcxz.png`),
          check: false,
          router: {
            name: "Base",
          },
        },
        {
          id: 5,
          name: "检验检测",
          selectNot: require(`@/assets/images/jyjcnot.png`),
          select: require(`@/assets/images/jyjcxz.png`),
          check: false,
          router: {
            name: "Inspect",
          },
        },
        {
          id: 6,
          name: "合格证",
          selectNot: require(`@/assets/images/hgznot.png`),
          select: require(`@/assets/images/hgzxz.png`),
          check: false,
          router: {
            name: "Certificate",
          },
        },
      ],
    };
  },
  watch: {},
  filters: {},
  created() {},
  mounted() {
    // this.loadMenu();
  },
  methods: {
    handleNavChange(item) {
      let { name } = item.router;
      this.$router.push({ name });
    },

    async loadMenu() {
      try {
        let res = await menuApi.getProjectMenu();
        this.meunlist = res;
      } catch (error) {}
    },
  },
};
