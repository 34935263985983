<template>
  <div class="home">
    <div class="container-con">
      <!-- <img class="bg" :src="require('@/assets/images/bg.png')" alt="" /> -->
      <div class="contnet-con-f">
        <nav-bar :list="meunlist" @change="handleNavChange"></nav-bar>
        <div class="bottom-box">
          <div class="main-con nptrem">
            <keep-alive>
              <!--使用keep-alive会将页面缓存-->
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </div>
          <div class="dibu-con">
            <img :src="require('@/assets/images/dibu.png')" alt="" />
            <span>Copyright 2019-2022. All Rights Reserved</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Index from "./index";
export default Index;
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
